// middleware/auth.js
export const authGuard = (to, from, next) => {
    const userAccessToken = localStorage.getItem("userAccessToken");
    const nextLink = localStorage.getItem("nextLink");

    if (userAccessToken) {
        if (nextLink) {
            // If a nextLink exists, redirect there and clear it
            localStorage.removeItem("nextLink");
            location.href = nextLink;
        } else {
            next(); // Proceed normally if no nextLink exists
        }
    } else {
        // Store the current full path as the next link for after login
        const currentLink = encodeURIComponent(to.fullPath);
        localStorage.setItem("nextLink", currentLink);

        // Redirect to the login page with the next_link in the URL
        const redirectUrl = `${process.env.ABOUT_FRONT_URL}?next_link=${currentLink}`;
        location.href = redirectUrl;
    }
};