import axios from "axios";
const cartService = (routeName) => `/cart-and-payment-service${routeName}`;
const mutations = {
    SET_SHOPING_CART_PROFILE(state, data) {
        state.userShopingCart = data
    },
    SET_PAYMENTS(state, data) {
        state.payments = data
    },
}

const state = () => ({
    userShopingCart: {},
    payments: [],
})

const getters = {
    userShopingCart: state => state.userShopingCart,
    payments: state => state.payments,
}


const actions = {
    /**
     * @shopingcart
     */
    getMyShopingCart({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(cartService(`/shopingcart/myactive`))
                .then(res => {
                    commit("SET_SHOPING_CART_PROFILE", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @payment
     */
    getMyPayments({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(cartService(`/payment?perPage=100`))
                .then(res => {
                    commit("SET_PAYMENTS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
}


export default {
    state,
    actions,
    mutations,
    getters,
}