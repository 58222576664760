import axios from "axios";
const pricingService = (routeName) => `/pricing-and-subscription-service${routeName}`;
const mutations = {
    /**
     * @pricing
     */
    SET_PRICINGS(state, data) {
        state.pricings = data;
    },
    SET_PRICING(state, data) {
        state.pricing = data;
    },
}

const state = () => ({
    pricing: {},
    pricings: [],
})

const getters = {
    pricing: state => state.pricing,
    pricings: state => state.pricings,
}


const actions = {
    /**
     * @pricing
     */
    getPricings({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(pricingService(`/pricingplan`))
                .then(res => {
                    commit("SET_PRICINGS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getPricing({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(pricingService(`/pricingplan/${id}`))
                .then(res => {
                    commit("SET_PRICING", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
}


export default {
    state,
    actions,
    mutations,
    getters,
}