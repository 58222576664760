import { authGuard } from "@/shared/middlewares/auth";
import { createMetaTags } from "@/shared/utils/meta";

export default [{
        path: "/v2/p/",
        component: () =>
            import ("@/shared/layouts/Dashboard"),
        // redirect: "",
        beforeEnter: authGuard,
        meta: createMetaTags(
            "Orang Code - Project",
            "Welcome to OrangCode's home. Discover tools and resources for your backend development journey."
        ),
        children: [{
                path: ":id",
                name: "Project",
                component: () =>
                    import ("@/modules/project/views/Project"),
                meta: createMetaTags(
                    "Orang Code - Project",
                    "Manage your project and explore tailored backend development solutions."
                ),
            },

        ],
    },


];