/* eslint-disable */
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';


import VueTour from 'vue-tour';
import 'vue-tour/dist/vue-tour.css';

import App from './App.vue';


import vuetify from './plugins/vuetify'
import router from "./router/index";
import store from "./shared/store";

import VueSocketIO from 'vue-socket.io';
import io from 'socket.io-client';





const socketInstance = io(process.env.SOCKET_IO_URL, {
    extraHeaders: {
        "userAccessToken": localStorage.getItem("userAccessToken")
    }
});


// Initialize the plugin with custom headers
Vue.use(new VueSocketIO({
    debug: true,
    connection: socketInstance,
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
}));

// Vue.use(VueSocketIO, socketInstance)

Vue.use(VueTour);

import "./axios/index";

Vue.config.productionTip = false

/**
 * String Prototype custome property
 */
if (!String.prototype.hasOwnProperty('capitalize')) {
    Object.defineProperty(String.prototype, 'capitalize', {
        value: function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        },
        enumerable: false
    });
}


Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
        vuetify,
        router,
        store,
        render(h) {
            return h(App, {
                props: {
                    // single-spa props are available on the "this" object. Forward them to your component as needed.
                    // https://single-spa.js.org/docs/building-applications#lifecycle-props
                    // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
                    /*
                    name: this.name,
                    mountParcel: this.mountParcel,
                    singleSpa: this.singleSpa,
                    */
                },
            });
        },
    },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;