import Vue from "vue";
import Router from "vue-router";



Vue.use(Router);

// Function to dynamically import routes from modules
const loadModuleRoutes = () => {
    const moduleRoutes = [];
    // Match any file ending with `.routes.js`
    const requireContext = require.context('@/modules', true, /\.routes\.js$/);

    requireContext.keys().forEach((file) => {
        const module = requireContext(file).default;
        if (Array.isArray(module)) {
            moduleRoutes.push(...module); // Assuming each module exports an array of routes
        } else {
            console.warn(`The module at ${file} does not export an array of routes.`);
        }
    });

    return moduleRoutes;
};


const globalRoutes = [{
        path: "/",
        name: "App",
        component: () =>
            import ("@/shared/layouts/Dashboard"),
        redirect: "/home",
        beforeEnter(to, from, next) {
            const admin = localStorage.getItem("userAccessToken");

            if (admin) {
                next();
            } else {
                location.href = process.env.ABOUT_FRONT_URL

            }
        },
        meta: {
            title: "OrangCode ",
            metaTags: [{
                    name: "description",
                    content: "OrangCode Nodejs backend development platforme",
                },
                {
                    property: "og:description",
                    content: "OrangCode Nodejs backend development platforme",
                },
            ],
        },
        children: [

        ]

    },
    {
        path: "/auth",
        name: "AuthApp",
        component: () =>
            import ("@/shared/layouts/Auth"),
        beforeEnter(to, from, next) {
            const admin = localStorage.getItem("userAccessToken");
            if (admin) {
                next("/home");
            } else {
                next();
            }
        },
        redirect: "/auth/signin",
        meta: {
            title: "OrangCode ",
            metaTags: [{
                    name: "description",
                    content: "OrangCode Signin page",
                },
                {
                    property: "og:description",
                    content: "OrangCode Signin page",
                },
            ],
        },
        children: [{
                path: "/auth/signin",
                name: "Signin",
                component: () =>
                    import ("@/modules/auth/views/Signin"),
            },

        ]
    },





    // {
    //     path: "*",
    //     redirect: "/",
    // },
];


// Merge global routes with module-specific routes
const routes = [...globalRoutes, ...loadModuleRoutes()];
//

const router = new Router({
    mode: "history",
    routes,
    base: '/',
});

// ...

// This callback runs before every route change, including on page load.

router.beforeEach((to, from, next) => {


    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(
        document.querySelectorAll("[data-vue-router-controlled]")
    ).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement("meta");

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute("data-vue-router-controlled", "");

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));

    next();
});

// ...


const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
            throw err;
        }
    });
};

export default router;