<template>
    <div>
        <v-alert class="white my-0 py-5" text dense color="read" icon="mdi-delete" border="left">
            <p class="">{{ what }}</p>
            <v-divider class="my-4 read" style="opacity: 0.22"></v-divider>

            <div>
                <div>
                    <div v-html="description" class="mb-0">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-end mt-5">
                <v-btn color="read" :loading="loading"  outlined @click="confirmDelete">{{ confirmText }}</v-btn>
            </div>
        </v-alert>
    </div>
</template>

<script>
import { methods } from '@/shared/utils/constant.js';

export default {
    props: {
        what: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: 'Ceci peux affecter les tables associer',
        },
        callback: {
            required: true,
        },
        successHandler: {
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        confirmText:{
            type:String,
            default:"Yes I Confirmed"
        }
    },
    data(){
        return {
            loading:false
        }
    },
    methods: {
        async confirmDelete() {
            try {
                this.loading = true
                await this.callback(this.data);
                this.successHandler();
                methods.dispatchSuccess(this.$store);
                methods.dispatchDeleteDialog(this.$store, {
                    dialog: false,
                    callback: null,
                    data: {},
                    what: '',
                    description: '',
                    successHandler: null,
                });
            } catch (err) {
                methods.dispatchError(this.$store);
            }finally{
                this.loading = false
            }
        },
    },
};
</script>

<style></style>
