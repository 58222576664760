import axios from "axios";
axios.defaults.baseURL = process.env.BACKEND_BASE_URL;


let token = "";
if (localStorage.getItem("userAccessToken")) {
    token = localStorage.getItem("userAccessToken");
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
}

// Add a response interceptor to handle 401 errors
axios.interceptors.response.use(
    (response) => {
        // If the request was successful, return the response
        return response;
    },
    (error) => {
        // If the response status is 401, handle it here
        if (error.response && error.response.status === 401) {
            localStorage.removeItem("userAccessToken");

            location.reload()
                // Perform actions to refresh the token or handle the unauthorized error
                // For example, you can redirect to the login page or show an error message
            console.log("Unauthorized request. Redirecting to login page...");
            // You can add your logic here to refresh the token or perform other actions

            // If you want to retry the original request after handling the 401 error,
            // you can do it here by returning a new Promise with the original request
            // return axios(error.config);
        }

        // If the error is not a 401 error, reject the promise with the error
        return Promise.reject(error);
    }
);


axios.defaults.headers.common["Content-Type"] = "application/json";