import { authGuard } from "@/shared/middlewares/auth";
import { createMetaTags } from "@/shared/utils/meta";

export default [{
    path: "/pricing",
    component: () =>
        import ("@/shared/layouts/Dashboard"),
    redirect: "/pricing",
    beforeEnter: authGuard,
    meta: createMetaTags("OrangCode - Pricing", "Explore OrangCode's pricing plans for backend development."),
    children: [{
            path: "",
            name: "Pricings",
            component: () =>
                import ("@/modules/pricing/views/Pricing"),
            meta: createMetaTags(
                "OrangCode - Pricing Plans",
                "Discover pricing plans tailored for your backend needs."
            ),
        },
        {
            path: ":id",
            name: "PricingDetail",
            component: () =>
                import ("@/modules/pricing/views/ShowPricing"),
            meta: createMetaTags(
                "OrangCode - Pricing Details",
                "Detailed information about OrangCode's pricing plans."
            ),
        },
    ],
}, ];