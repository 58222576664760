import axios from "axios";
const projectService = (routeName) => `/js-modular-monolith-express-service${routeName}`;
const mutations = {


    /**
     * @appMiddleware
     */

    SET_PROJECT_APP(state, data) {
        state.projectApp = data;
    },
    SET_PROJECT_APP_BACKEND_MODULE(state, data) {
        state.projectAppBackendModule = data;
    },

    SET_APP_MIDDLEWARE_METHODS(state, data) {
        state.appMiddlewareMethods = data;
    },


}

const state = () => ({


    projectApp: {},

})

const getters = {

    projectApp: state => state.projectApp,


}


const actions = {

    getProjectApp({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/app/${id}`))
                .then(res => {
                    commit("SET_PROJECT_APP", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


}


export default {
    state,
    actions,
    mutations,
    getters,
}