import axios from "axios";

const userService = (routeName) => `/user-service${routeName}`;

const mutations = {
    SET_USER_PROFILE(state, userProfile) {
        state.userProfile = userProfile
    },

    /**
     * @user
     */
    SET_USERS(state, data) {
        state.users = data;
    },
    SET_USER(state, data) {
        state.user = data;
    },
}

const state = () => ({
    userProfile: {},
    users: [],
    user: {},
})

const getters = {
    userProfile: state => state.userProfile,
    getUser(state) {
        return state.user
    },
    user: state => state.user,
    users: state => state.users,
}


const actions = {

    /**
     * @profile
     */
    getUserProfile({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(userService(`/user/profile`))
                .then(res => {
                    commit("SET_USER_PROFILE", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    /**
     * @user
     */
    getUsers({ commit }, schema) {
        return new Promise((resolve, reject) => {

            axios
                .get(userService(`/user?withoutMyAccount=${schema && schema.withoutMyAccount ? true : false }&email=${schema.email}&workspace=${schema && schema.workspace ? schema.workspace : '' }`))
                .then(res => {
                    commit("SET_USERS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getUser({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/user/${id}`)
                .then(res => {
                    commit("SET_USER", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

}


export default {
    state,
    actions,
    mutations,
    getters,
}