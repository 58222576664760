import axios from "axios";
const projectService = (routeName) => `/js-modular-monolith-express-service${routeName}`;
const mutations = {
    /**
     * @project
     */
    SET_PROJECTS(state, data) {
        state.projects = data;
    },
    SET_PROJECT(state, data) {
        state.project = data;
    },

    /**
     * @appMiddleware
     */

    SET_PROJECT_APP(state, data) {
        state.projectApp = data;
    },
    SET_PROJECT_APP_BACKEND_MODULE(state, data) {
        state.projectAppBackendModule = data;
    },

    SET_APP_MIDDLEWARE_METHODS(state, data) {
        state.appMiddlewareMethods = data;
    },
    /**
     * @appBackendModule
     */
    SET_PROJECT_APP_BACKEND_MODULE_SERVICES(state, data) {
        state.projectAppBackendModuleServices = data;
    },
    SET_PROJECT_APP_BACKEND_MODULE_CONTROLLERS(state, data) {
        state.projectAppBackendModuleControllers = data;
    },
    SET_PROJECT_APP_BACKEND_MODULE_VALIDATIONS(state, data) {
        state.projectAppBackendModuleValidations = data;
    },
    SET_PROJECT_APP_BACKEND_MODULE_ROUTES(state, data) {
        state.projectAppBackendModuleRoutes = data;
    },
    /**
     * @model
     */
    SET_MODULE_MODELS(state, data) {
        state.moduleModels = data;
    },
    SET_APP_MODULE_MODEL_ENTITY_SERVICE_PACKS(state, data) {
        state.appModuleModelEntityServicePacks = data;
    },
    SET_MODEL(state, data) {
        state.model = data;
    },
    /**
     * @attribute
     */
    SET_ATTRIBUTES(state, data) {
        state.attributes = data;
    },
    SET_ATTRIBUTE(state, data) {
        state.attribute = data;
    },

}

const state = () => ({
    projects: [],
    project: {},


})

const getters = {
    project: state => state.project,
    projects: state => state.projects,

}


const actions = {
    /**
     * @project
     */
    getProjects({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/project`)
                .then(res => {
                    commit("SET_PROJECTS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getProject({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/project/${id}`))
                .then(res => {
                    commit("SET_PROJECT", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
}


export default {
    state,
    actions,
    mutations,
    getters,
}