import { authGuard } from "@/shared/middlewares/auth";
import { createMetaTags } from "@/shared/utils/meta";

export default [{
    path: "/shoppingcart",
    component: () =>
        import ("@/shared/layouts/Dashboard"),
    redirect: "/shoppingcart",
    beforeEnter: authGuard,
    meta: createMetaTags(
        "OrangCode - Shopping Cart ",
        "View and manage the items in your OrangCode shopping cart. Complete your checkout with ease."
    ),
    children: [{
            path: "",
            name: "ShoppingCart",
            component: () =>
                import ("@/modules/shoppingcart/views/ShoppingCart"),
            meta: createMetaTags(
                "OrangCode - Your Shopping Cart ",
                "Review your selected items and services in the shopping cart before completing your purchase."
            ),
        },
        {
            path: "/checkoutshoppingcart",
            name: "Checkout",
            component: () =>
                import ("@/modules/shoppingcart/views/Checkout"),
            meta: createMetaTags(
                "OrangCode - Checkout ",
                "Proceed to checkout to finalize your order. Secure and simple payment options available."
            ),
        },
        {
            path: "/payment-failed",
            name: "PaymentFailed",
            component: () =>
                import ("@/modules/shoppingcart/views/PaymentFailed"),
            meta: createMetaTags(
                "OrangCode - Payment Failed ",
                "Proceed to checkout to finalize your order. Secure and simple payment options available."
            ),
        },
        {
            path: "/payment",
            name: "PaymentHistory",
            component: () =>
                import ("@/modules/shoppingcart/views/PaymentHistory"),
            meta: createMetaTags(
                "OrangCode - Payment History ",
                "Proceed to checkout to finalize your order. Secure and simple payment options available."
            ),
        },
    ],
}, ];