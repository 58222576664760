import { authGuard } from "@/shared/middlewares/auth";
import { createMetaTags } from "@/shared/utils/meta";

export default [{
        path: "/home",
        component: () =>
            import ("@/shared/layouts/Dashboard"),
        redirect: "/home",
        beforeEnter: authGuard,
        meta: createMetaTags(
            "OrangCode - Home",
            "Welcome to OrangCode's home. Discover tools and resources for your backend development journey."
        ),
        children: [{
            path: "",
            name: "Workspace",
            component: () =>
                import ("@/modules/workspace/views/Workspace"),
            meta: createMetaTags(
                "OrangCode - Workspace",
                "Manage your workspace and explore tailored backend development solutions."
            ),
        }, ],
    },

    {
        path: "/project",
        component: () =>
            import ("@/shared/layouts/Dashboard"),
        beforeEnter: authGuard,
        meta: createMetaTags(
            "OrangCode - Project",
            "Welcome to OrangCode's home. Discover tools and resources for your backend development journey."
        ),
        children: [{
            path: ":id",
            name: "Project",
            component: () =>
                import ("@/modules/workspace/views/Project"),
            meta: createMetaTags(
                "OrangCode - Project",
                "Manage your workspace and explore tailored backend development solutions."
            ),
        }, ],
    },
];