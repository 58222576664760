import axios from "axios";
const mutations = {
    /**
     * @template
     */
    SET_TEMPLATES(state, data) {
        state.templates = data;
    },
    SET_TEMPLATE(state, data) {
        state.template = data;
    },

}

const state = () => ({
    template: {},
    templates: [],

})

const getters = {

}

const actions = {


    /**
     * @template
     */
    getTemplates({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/template`)
                .then(res => {
                    commit("SET_TEMPLATES", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getTemplate({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/template/${id}`)
                .then(res => {
                    commit("SET_TEMPLATE", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },



}


export default {
    state,
    actions,
    mutations,
    getters,
}