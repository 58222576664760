import axios from "axios";
const workspaceService = (routeName) => `/workspace-service${routeName}`;
const mutations = {

    /**
     * @workspace
     */
    SET_WORSPACES(state, data) {
        state.workspaces = data;
    },
    SET_GUEST_WORSPACES(state, data) {
        state.guestWorkspaces = data;
    },
    SET_WORSPACE(state, data) {
        state.workspace = data;
    },
    SET_WORSPACE_INVITATIONS(state, data) {
        state.workspaceInvitations = data;
    },
}

const state = () => ({
    workspaces: [],
    guestWorkspaces: [],
    workspace: {},
    workspaceInvitations: [],
})

const getters = {
    workspace: state => state.workspace,
    workspaces: state => state.workspaces,
    guestWorkspaces: state => state.guestWorkspaces,
    workspaceInvitations: state => state.workspaceInvitations,
}


const actions = {
    /**
     * @workspace
     */
    getWorkspaces({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(workspaceService(`/workspace?myWorkspace=true`))
                .then(res => {
                    commit("SET_WORSPACES", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getGuestWorkspaces({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(workspaceService(`/workspace?myWorkspace=false`))
                .then(res => {
                    commit("SET_GUEST_WORSPACES", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getWorkspace({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(workspaceService(`/workspace/${id}`))
                .then(res => {
                    commit("SET_WORSPACE", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getWorkspaceInvitations({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(workspaceService(`/workspace/invitation?id=${id || ''}`))
                .then(res => {
                    commit("SET_WORSPACE_INVITATIONS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getProject({}, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(workspaceService(`/project/${id}/redirect-to-service`))
                .then(res => {
                    // commit("SET_PROJECT", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
}


export default {
    state,
    actions,
    mutations,
    getters,
}