import { authGuard } from "@/shared/middlewares/auth";
import { createMetaTags } from "@/shared/utils/meta";

export default [{
        path: "/v2/p/",
        component: () =>
            import ("@/shared/layouts/Dashboard"),
        // redirect: "",
        beforeEnter: authGuard,
        meta: createMetaTags(
            "Orang Code - Home",
            "Welcome to OrangCode's home. Discover tools and resources for your backend development journey."
        ),
        children: [{
                path: "app/:id",
                name: "Project App",
                component: () =>
                    import ("@/modules/app/views/ProjectApp"),
                meta: createMetaTags(
                    "Orang Code - Project App",
                    "Manage your app and explore tailored backend development solutions."
                ),
            },
            // {
            //     path: "app/module/:id",
            //     name: "Project App Module",
            //     component: () =>
            //         import ("@/modules/project/views/ProjectAppModule"),
            //     meta: createMetaTags(
            //         "Orang Code - Project App Module",
            //         "Manage your project and explore tailored backend development solutions."
            //     ),
            // },

        ],
    },


];