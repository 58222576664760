import { authGuard } from "@/shared/middlewares/auth";
import { createMetaTags } from "@/shared/utils/meta";

export default [{
    path: "/subscription",
    component: () =>
        import ("@/shared/layouts/Dashboard"),
    redirect: "/subscription",
    beforeEnter: authGuard,
    meta: createMetaTags(
        "OrangCode - Subscription Plans",
        "Explore OrangCode's subscription plans tailored for your backend development needs."
    ),
    children: [{
            path: "",
            name: "Subscription",
            component: () =>
                import ("@/modules/subscription/views/Subscription"),
            meta: createMetaTags(
                "OrangCode - Subscription",
                "Discover subscription options designed for your backend development needs."
            ),
        },
        {
            path: "/active-subscription",
            name: "ActiveSubscription",
            component: () =>
                import ("@/modules/subscription/views/ActiveSubscription"),
            meta: createMetaTags(
                "OrangCode - Active Subscription",
                "View and manage your active subscription plans with OrangCode."
            ),
        },
        {
            path: "/subscription-active-successfully",
            name: "ActiveSubscription",
            component: () =>
                import ("@/modules/subscription/views/ActiveSubscriptionSuccessfully"),
            meta: createMetaTags(
                "OrangCode - Subscription Activated",
                "Your subscription has been activated successfully. Start building now!"
            ),
        },
        {
            path: "/subscription-failed",
            name: "SubscriptionFailed",
            component: () =>
                import ("@/modules/subscription/views/SubscriptionFailed"),
            meta: createMetaTags(
                "OrangCode - Subscription Failed",
                "Your subscription process failed. Please try again."
            ),
        },
    ],
}, ];