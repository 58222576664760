// store/index.js
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import Universal from "./modules/universal/index";

import User from "./modules/user/index";

const modules = { Universal, User };

const loadModules = (modules) => {
    const requireContext = require.context(
        "@/modules",
        true,
        /\.vuex\.module\.js$/ // Match files ending with .vuex.module.js
    );

    requireContext.keys().forEach((file) => {
        const moduleName = file
            .replace(/^\.\//, "") // Remove the initial "./"
            .replace(/\/index\.vuex\.module\.js$/, "") // Remove the "/index.vuex.module.js" suffix
            .replace(/\.vuex\.module\.js$/, ""); // Remove the ".vuex.module.js" suffix

        const module = requireContext(file).default;

        modules[moduleName] = {
            namespaced: false,
            ...module,
        };
    });

    return modules;
};

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: loadModules(modules),
});