const pricingService = (routeName) => `/pricing-and-subscription-service${routeName}`;
import axios from "axios";
const mutations = {
    /**
     * @subscription
     */
    SET_SUBSCRIPTIONS(state, data) {
        state.subscriptions = data;
    },
    SET_ACTIVE_SUBSCRIPTION(state, data) {
        state.activesSubscription = data;
    },
    SET_SUBSCRIPTION(state, data) {
        state.subscription = data;
    },
}

const state = () => ({
    activesSubscription: null,
    subscription: {},
    subscriptions: [],
})

const getters = {
    activesSubscription: state => state.activesSubscription,
    subscription: state => state.subscription,
    subscriptions: state => state.subscriptions,
}


const actions = {

    /**
     * @subscription
     */
    getSubscriptionList({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/subscription/my`)
                .then(res => {
                    commit("SET_SUBSCRIPTIONS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    getActiveSubscription({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(pricingService(`/subscription/profile/active`))
                .then(res => {
                    commit("SET_ACTIVE_SUBSCRIPTION", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
}


export default {
    state,
    actions,
    mutations,
    getters,
}